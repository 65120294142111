<template>
    <div class="pvhFactory">
        <basic-container>
            <div class="layTop">
                <div class="Title" style="margin-bottom:20px;">{{$t('SupplierSectionList')}}</div>
            </div>
            <template>
                <avue-crud  :option="setData.tableOpt" :data="tableData" :row-style="rowStyle" :page.sync="page" @current-change="currentChange"  >
                    <template slot="menuRight" style="width: calc(100% - 80px);">
                        <el-row :gutter="20" class="topFromSearch">
                            <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" class="fromOutDiv">
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('Code')}}:</span>
                                    <el-input v-model="codeVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                                <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                    <span>{{$t('CATEGORY1')}}:</span>
                                    <el-input v-model="nameVal" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                                </el-col>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 25px; text-align: right; align-self: flex-end;">
                                <el-button :title="$t('Search')" icon="iconfont sousuo" size="mini" type="primary" @click="searchFrom">  {{$t('Search')}}</el-button>
                                <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addven"> {{$t('New')}}</el-button>
                            </el-col>

                        </el-row>
                    </template>
                    <template slot-scope="{row,index,size,type}" slot="menu">
                        <el-button icon="el-icon-set-up" type="text" size="medium"  :title="$t('Process')" @click="editVen(row)"></el-button>
                    </template>
                </avue-crud>
            </template>
        </basic-container>
    </div >
</template>
<script>
    import { delVendor} from "@/api/supplierList";
    import { vendorList} from "@/api/supplierList";
    
    export default {
        data(){
            return {
                codeVal: "",
                nameVal: "",
                statusVal: "",
                groupList: [],
                tableData: [],
                YesNoData: [
                    {
                        label: this.$t('False'),
                        value: false
                    }, {
                        label: this.$t('True'),
                        value: true
                    }

                ],
                statusData: [
                    {
                        label: this.$t('Delete'),
                        value: false
                    }, {
                        label: this.$t('normal'),
                        value: true
                    }

                ],
                page: {
                    currentPage: 1,
                    total: 0,
                    layout: "total,pager,prev,next",
                    background: true,
                    pageSize:10
                }
            }
        },
        computed: {
            setData() {
                return {
                    tableOpt: {
                        page: true,
                        refreshBtn: false,
                        editBtn: false,
                        delBtn: false,
                        emptyText: this.$t('No Data'),
                        menuTitle: this.$t('OPERATION'),
                        menuWidth: 140,
                        addBtnText: this.$t("ADD"),
                        align: 'center',
                        columnBtn: false,
                        simplePage: false,
                        editTitle: this.$t('Edit'),
                        addTitle: this.$t('Add'),
                        addBtn: false,
                        header: true,
                        column: [
                            {
                                label: this.$t('SORT'),
                                prop: 'sort',
                            },
                            {
                                label: this.$t('Code'),
                                prop: 'code',
                            },
                            {
                                label: this.$t('CATEGORY1'),
                                prop: 'name',
                            },
                            {
                                label: this.$t('ShortName'),
                                prop: 'shortName',
                            },
                            {
                                label: this.$t('LOSSRATEMIN'),
                                prop: 'lossRateMin',
                            },
                            {
                                label: this.$t('LOSSRATEMAX'),
                                prop: 'lossRateMax',
                            },
                            {
                                label: this.$t('isLastStep'),
                                prop: 'isLastStep',
                                type: "select",
                                dicData: this.YesNoData,
                            },
                            {
                                label: this.$t('STATUS'),
                                prop: 'status',
                                type: "select",
                                dicData: this.statusData,
                            }
                        ]
                    }
                }
            }
        },
        created() {
            this.getvendorList();
        },
        methods: {
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }

            },//表格行样式
            rowStyle({rowIndex }) {
                if (rowIndex % 2 === 0) {
                    return {
                        backgroundColor: '#F8F8F8'
                    }
                }
            },//切换分页
            currentChange(val) {
                this.page.currentPage = val
                this.getvendorList();
            },//搜索
            searchFrom() {
                this.getvendorList();
            },//编辑数据 跳转到流程页面

            //删除数据
            delVendor(row) {
                this.$confirm(this.$t('IsDel') + row.name, this.$t('Tips'), {
                    confirmButtonText: this.$t('Yes'),
                    cancelButtonText: this.$t('Cancel'),
                    type: "warning"
                }).then(() => {
                    delVendor(row.id).then(() => {
                        this.getvendorList();
                        this.$message({ showClose: true, message: this.$t('DeleteSuccess'), type: "success" });
                    });
                })
            },
            //显示查询供应商类别数据列表
            async getvendorList() {
                await vendorList(this.codeVal, this.nameVal, this.page.currentPage, this.page.pageSize).then(res => {
                    this.tableData = res.data.vendorSections;
                    this.page.total = res.data.totalCount;
                    this.page.pageSize = res.data.pageSize;
                }).catch(erro => {
                    console.log(erro);
                })
            },//添加跳转
            addven() {
                this.$router.push({ path: '/pvh_setting/supplierAdd', query: { sign: 'add', ID: '' } });
            },//修改跳转
            editVen(row) {
                this.$router.push({ path: '/pvh_setting/supplierAdd', query: { sign: 'edit', ID: row.id} });
            },
        }
    }
</script>